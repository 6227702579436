import React from 'react';
import Juggler from './jugglingProps/juggler';
import Unicycle from './jugglingProps/unicycle';
import Diabolo from './jugglingProps/diabolo';
import Devilstick from './jugglingProps/devilstick';

export default function Footer(data) {
    function Egg() {
        if (data.easterEggNum === 0) {
            return <Juggler />
        } else if (data.easterEggNum === 1) {
            return <Unicycle />
        } else if (data.easterEggNum === 2) {
            return <Diabolo />
        } else if (data.easterEggNum === 3) {
            return <Devilstick />
        } else {
            return <Juggler />
        }
    }

    return (
        <footer className="njf-footer bg-dark text-black-20 py-4">
            <div className="njf-footer-2 d-flex justify-content-between col-md-6 col-md-2">
                <small>Copyright &copy; stichtingnjf.nl</small>
                <small>KvK: 41094342</small>
                <small>Vestigingsadres: Wielewaalweg 12 5915 BX Venlo</small>
                <small><a href='https://tickets.stichtingnjf.nl/algemene-voorwaarden/'>Algemene Voorwaarden</a></small>
                {/* <Egg /> */}
            </div>
        </footer>
    )
}
